/* eslint-disable camelcase */
import React, { useLayoutEffect } from 'react';
import MetaTags from '../Header/MetaTags';
import './style.css';
import { useIntl } from 'gatsby-plugin-intl';

export default function Cookies({ data }) {
  const intl = useIntl();
  const { cookiePolicyData } = data[intl.locale].acf;
  const { yoast_head_json } = data[intl.locale];

  useLayoutEffect(() => {
    if (window.location.pathname.includes('/cookie-policy')) {
      const script = document.createElement('script')

      script.src =
        'https://consent.cookiebot.com/e657605b-2768-41fd-a357-b76b5baf8b9b/cd.js'
      script.async = true
      try {
        document.getElementById('cookies').appendChild(script)
      } catch (err) {
        console.log(err)
      }

      return () => {
        document.getElementById('cookies') &&
          document.getElementById('cookies').removeChild(script)
      }
    }
  }, [])

  return (
    <>
      <MetaTags data={yoast_head_json} />
      <div id="cookies">
        <h1 className="cookieTitle">{cookiePolicyData.cookieTitle}</h1>
        {cookiePolicyData.cookieContent && (
          <div
            className="cookieContent"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: cookiePolicyData.cookieContent,
            }}
          />
        )}
      </div>
    </>
  );
}
